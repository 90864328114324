<template>
  <div v-if="visibleModalInfoBot" class="modal" @click="closeModalInfoBot">
    <div class="modal-dialog-n modal-dialog-scrollable" @click.stop>
      <div class="modal-content">
        <div class="modal-body">
          <button class="close-button" @click="closeModalInfoBot">X</button>
          <div class="container-tittle">
            <img src="../assets/bot-svgrepo-com.png" alt="Imagem" class="imagem-tittle me-1" />
            <div class="texto-tittle">
              <h3>Triagem do bot</h3>
            </div>
          </div>
<!--          <div class="container-tittle">-->
<!--            <div class="texto-institution">-->
<!--              <p>Instituição:</p>-->
<!--            </div>-->
<!--            <div class="texto-hospital">-->
<!--              <p>{{ protocolInfoPopup.protocol_info.company }}</p>-->
<!--            </div>-->
<!--          </div>-->

          <div v-if="protocolInfoPopup.nurse_info == null">
            <div class="row margin-top">
                <div class="col">
                    <div class="d-grid gap-2">
                        <div @click="ChangeCollapedGeralInfo"
                             class="btn-solicitante d-flex justify-content-between align-items-center" style="height: auto"
                             data-bs-toggle="collapse" data-bs-target="#collapse-b0" aria-expanded="false"
                             aria-controls="collapse-b0">
                            <span class="ms-1"><b>Instituição:</b></span>
                            <span v-if="isCollapedGeralInfo" class="me-1">▲</span>
                            <span v-else class="me-1">▼</span>
                        </div>
                    </div>
                </div>
            </div>
            <div id="myGroup">
                <div class="collapse" id="collapse-b0" data-bs-parent="#myGroup">
                    <div class="mt-3">
                        <div style="margin-left: 5px;">
                            <div class="container-info">
                                <p class="texto-regular" style="text-align: left; margin: 0;">Nome: <b>{{ protocolInfoPopup.responsible_info.companies_info.map(company => company.name).join(', ') }}</b> </p>
                            </div>
                            <div class="container-info">
                                <p class="texto-regular" style="text-align: left; margin: 0;">Sigla: <b>{{ protocolInfoPopup.responsible_info.companies_info.map(company => company.code).join(', ') }}</b> </p>
                            </div>
                            <div class="container-info">
                                <p class="texto-regular" style="text-align: left; margin: 0;">Cidade: <b>{{ protocolInfoPopup.responsible_info.companies_info.map(company => company.city).join(', ') }}</b></p>
                            </div>
                            <div class="container-info">
                                <p class="texto-regular" style="text-align: left; margin: 0;">Hospital de referência: <b> {{ protocolInfoPopup.responsible_info.companies_info.map(company => company.reference).join(', ') }} </b></p>
                            </div>
                            <div class="container-info">
                                <p class="texto-regular" style="text-align: left; margin: 0;">Cidade de referência: <b> {{ protocolInfoPopup.responsible_info.companies_info.map(company => company.city_reference).join(', ') }} </b></p>
                            </div>
                            <div class="container-info">
                                <p class="texto-regular" style="text-align: left; margin: 0;">Distância: <b>{{ protocolInfoPopup.responsible_info.companies_info.map(company => company.distance_to_reference).join(', ') }}km </b></p>
                            </div>
                            <div class="container-info texto-regular obs-list">
                                <ul>
                                  <li>Observações:</li>
                                    <ul>
                                        <li>URL DOS PAC's:
                                            <b class="link_excel" style="text-align: left; margin: 0;">
                                              <a href="https://docs.google.com/spreadsheets/d/18FkBLonGZD4H1dZ8tqwC3xaUAKYT7lfdc5XO0Sh4tms/edit?gid=0#gid=0" target="_blank">
                                                  Clique aqui para acessar
                                              </a>
                                            </b>
                                        </li>
                                        <li>
                                            Código: <b>{{ protocolInfoPopup.responsible_info.companies_info.map(company => company.code).join(', ') }}</b>
                                        </li>
                                        <li>
                                            Acesso: <b>{{protocolInfoPopup.protocol_info.company}}</b>
                                        </li>
                                        <li>
                                            Senha: <b>{{ protocolInfoPopup.responsible_info.companies_info.map(company => company.key).join(', ') }}</b>
                                        </li>
                                    </ul>
                                </ul>
                            </div>
                            <div class="container-info">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="separate"></div>

              <div class="row margin-top">
              <div class="col">
                <div class="d-grid gap-2">
                  <div @click="ChangeCollapedSolicitantVisible"
                    class="btn-solicitante d-flex justify-content-between align-items-center" style="height: auto"
                    data-bs-toggle="collapse" data-bs-target="#collapse-b1" aria-expanded="false"
                    aria-controls="collapse-b1">
                    <span class="ms-1"><b>Solicitante e Responsável:</b></span>
                    <span v-if="isCollapedSolicitantVisible" class="me-1">▲</span>
                    <span v-else class="me-1">▼</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="myGroup">
              <div class="collapse" id="collapse-b1" data-bs-parent="#myGroup">
                <div class="mt-3">
                  <div style="margin-left: 5px;">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Solicitante e Responsável: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.responsible_info.name
                        }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Profissão:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.responsible_info.professional_type }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Registro:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.responsible_info.professional_code }}-{{
    protocolInfoPopup.responsible_info.state_code }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row margin-top">
              <div class="col">
                <div class="d-grid gap-2">
                  <div @click="ChangeCollapedSolicitantVisible"
                    class="btn-solicitante d-flex justify-content-between align-items-center" style="height: auto"
                    data-bs-toggle="collapse" data-bs-target="#collapse-b1" aria-expanded="false"
                    aria-controls="collapse-b1">
                    <span class="ms-1"><b>Solicitante e Responsável:</b></span>
                    <span v-if="isCollapedSolicitantVisible" class="me-1">▲</span>
                    <span v-else class="me-1">▼</span>
                  </div>
                </div>
              </div>
            </div>
            <div id="myGroup">
              <div class="collapse" id="collapse-b1" data-bs-parent="#myGroup">
                <div class="mt-3">
                  <div style="margin-left: 5px;">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Solicitante: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.nurse_info.name
                        }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Profissão:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.nurse_info.professional_type }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Registro:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.nurse_info.professional_code }}-{{
    protocolInfoPopup.nurse_info.state_code }}</p>
                    </div>
                  </div>
                </div>
                <div class="mt-3" style="margin-top: 15px;">
                  <div style="margin-left: 5px;">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Responsável: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.responsible_info.name
                        }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Profissão:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.responsible_info.professional_type }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Registro:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.responsible_info.professional_code }}-{{
    protocolInfoPopup.responsible_info.state_code }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="separate"></div>

          <div class="row margin-top">
            <div class="col">
              <div class="d-grid gap-2">
                <div @click="ChangeCollapedPacientVisible"
                  class="btn-solicitante d-flex justify-content-between align-items-center" style="height: auto"
                  data-bs-toggle="collapse" data-bs-target="#collapse-b2" aria-expanded="false"
                  aria-controls="collapse-b2">
                  <span class="ms-1"><b>Paciente:</b></span>
                  <span v-if="isCollapedPacientVisible" class="me-1">▲</span>
                  <span v-else class="me-1">▼</span>
                </div>
              </div>
            </div>
          </div>

          <div id="myGroupPacient">
            <div class="collapse" id="collapse-b2" data-bs-parent="#myGroupPacient">
              <div class="mt-3">
                <div style="margin-left: 5px;">
                  <div v-if="protocolInfoPopup.protocol_info.patient_info.mother_info === null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Paciente: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatName(protocolInfoPopup.protocol_info.patient_info.name) }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">DN: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    calcularIdade(protocolInfoPopup.protocol_info.patient_info.birthdate) }}</p>
                    </div>
                    <div v-if="protocolInfoPopup.protocol_info.patient_info.document !== null">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">{{
    protocolInfoPopup.protocol_info.patient_info.document_type }}: </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    formatDocument(protocolInfoPopup.protocol_info.patient_info.document_type,
      protocolInfoPopup.protocol_info.patient_info.document) }}</p>
                      </div>
                    </div>
                    <div v-if="protocolInfoPopup.protocol_info.patient_info.document_type !== 'RG'">
                      <div
                        v-if="protocolInfoPopup.protocol_info.patient_info.document_issuer !== null && protocolInfoPopup.protocol_info.patient_info.document_issuer.replace(' ', '') !== ''">
                        <div class="container-info">
                          <p class="texto-regular" style="text-align: left; margin: 0;"> Orgão Emissor: </p>
                          <p class="texto-regular-bold" style="text-align: left;"> {{
    protocolInfoPopup.protocol_info.patient_info.document_issuer }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Nome da mãe: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatName(protocolInfoPopup.protocol_info.patient_info.mother_info.name) }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">DN da mãe: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    calcularIdade(protocolInfoPopup.protocol_info.patient_info.mother_info.birthdate) }}</p>
                    </div>
                    <div v-if="protocolInfoPopup.protocol_info.patient_info.mother_info.document !== null">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">{{
    protocolInfoPopup.protocol_info.patient_info.mother_info.document_type }} da mãe: </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    formatDocument(protocolInfoPopup.protocol_info.patient_info.mother_info.document_type,
      protocolInfoPopup.protocol_info.patient_info.mother_info.document) }}</p>
                      </div>
                    </div>
                    <div v-if="protocolInfoPopup.protocol_info.patient_info.mother_info.document_type !== 'RG'">
                      <div
                        v-if="protocolInfoPopup.protocol_info.patient_info.mother_info.document_issuer !== null && protocolInfoPopup.protocol_info.patient_info.mother_info.document_issuer.replace(' ', '') !== ''">
                        <div class="container-info">
                          <p class="texto-regular" style="text-align: left; margin: 0;"> Orgão Emissor: </p>
                          <p class="texto-regular-bold" style="text-align: left;"> {{
    protocolInfoPopup.protocol_info.patient_info.mother_info.document_issuer }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Paciente: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatName(protocolInfoPopup.protocol_info.patient_info.name) }}</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">DN do Paciente: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    calcularIdade(protocolInfoPopup.protocol_info.patient_info.birthdate) }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="protocolInfoPopup.protocol_info.history.length > 0">

            <div class="separate"></div>

            <div class="row margin-top">
              <div class="col">
                <div class="d-grid gap-2">
                  <div @click="ChangeCollapedHistoricPacientVisible"
                    class="btn-solicitante d-flex justify-content-between align-items-center" style="height: auto"
                    data-bs-toggle="collapse" data-bs-target="#collapse-b3" aria-expanded="false"
                    aria-controls="collapse-b3">
                    <span class="ms-1"><b>Histórico do paciente:</b></span>
                    <span v-if="isCollapedHistoricPacientVisible" class="me-1">▲</span>
                    <span v-else class="me-1">▼</span>
                  </div>
                </div>
              </div>
            </div>

            <div id="myGroupHistoricPacient">
              <div class="collapse" id="collapse-b3" data-bs-parent="#myGroupHistoricPacient">
                <div class="mt-3">
                  <div style="margin-left: 5px;">
                    <div v-for="(item, index) in protocolInfoPopup.protocol_info.history" :key="index">
                      <div class="container-info" style="margin-top: 15px;">
                        <div @click="handleItemHistoricClick(item.report_id)" class="clicavel">
                          <img src="../assets/link-external-historic.png" alt="Imagem" class="imagem" />
                          <p class="texto-regular-bold" style="text-align: left; color: #6200EE;"> {{
    formatHistoric(item)
  }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div v-if="protocolInfoPopup.files.length > 0">
            <div class="separate"></div>

            <div class="row margin-top">
              <div class="col">
                <div class="d-grid gap-2">
                  <div @click="ChangeCollapedFilesVisible"
                    class="btn-info-bot d-flex justify-content-between align-items-center" style="height: auto"
                    data-bs-toggle="collapse" data-bs-target="#collapse-b4" aria-expanded="false"
                    aria-controls="collapse-b4">
                    <span class="ms-1">Arquivos:</span>
                    <span v-if="isCollapedInfoVisible" class="me-1">▲</span>
                    <span v-else class="me-1">▼</span>
                  </div>
                </div>
              </div>
            </div>

            <div id="myGroupFiles">
              <div class="collapse" id="collapse-b4" data-bs-parent="#myGroupFiles">
                <div class="mt-3">
                  <div style="margin-left: 5px;">
                    <div v-for="(item, index) in protocolInfoPopup.files" :key="index">
                      <div class="container-info" style="margin-top: 15px;">
                        <div @click="handleItemFileClick(item.id)" class="clicavel">
                          <div v-if="item.extension.includes('pdf')">
                            <img src="../assets/link-external-document_file.png" alt="Imagem" class="imagem" />
                          </div>
                          <div
                            v-else-if="item.extension.includes('png') || item.extension.includes('jpg') || item.extension.includes('jpeg')">
                            <img src="../assets/link-external-image_file.png" alt="Imagem" class="imagem" />
                          </div>
                          <div v-else-if="item.extension.includes('mp3') || item.extension.includes('ogg')">
                            <img src="../assets/link-external-audio_file.png" alt="Imagem" class="imagem" />
                          </div>
                          <div v-else-if="item.extension.includes('mp4')">
                            <img src="../assets/link-external-video_file.png" alt="Imagem" class="imagem" />
                          </div>
                          <div v-else>
                            <img src="../assets/link-external-historic.png" alt="Imagem" class="imagem" />
                          </div>
                          <p class="texto-regular-bold" style="text-align: left; color: #6200EE;"><strong>{{
    getTypeFile(item.extension) }}</strong> - {{ item.file_name }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="separate"></div>

          <div class="row margin-top">
            <div class="col">
              <div class="d-grid gap-2">
                <div @click="ChangeCollapedInfoVisible"
                  class="btn-info-bot d-flex justify-content-between align-items-center" style="height: auto"
                  data-bs-toggle="collapse" data-bs-target="#collapse-b5" aria-expanded="false"
                  aria-controls="collapse-b5">
                  <span class="ms-1"><b>Dados do Bot:</b></span>
                  <span v-if="isCollapedInfoVisible" class="me-1">▲</span>
                  <span v-else class="me-1">▼</span>
                </div>
              </div>
            </div>
          </div>

          <div id="myGroupInfo">
            <div class="collapse" id="collapse-b5" data-bs-parent="#myGroupInfo">
              <div class="mt-3">
                <div style="margin-left: 5px;">
                  <div v-if="protocolInfoPopup.AVC_protocol !== null && protocolInfoPopup.AVC_protocol == 1">
                    <div style="background-color: #FFE5F5;">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">PROTOCOLO DE AVC PARA TROMBÓLISE:
                        </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.AVC_protocol) }}</p>
                      </div>
                      <div v-if="protocolInfoPopup.neurological_deficit_duration_id != null">
                        <div class="container-info">
                          <p class="texto-regular" style="text-align: left; margin: 0;">Tempo do início dos sintomas:
                          </p>
                          <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.neurological_deficit_duration_name }}</p>
                        </div>
                      </div>
                      <div v-if="protocolInfoPopup.neurological_deficit_name != null">
                        <div class="container-info">
                          <p class="texto-regular" style="text-align: left; margin: 0;"> Déficit neurológico: </p>
                          <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.neurological_deficit_name }}</p>
                        </div>
                      </div>
                      <div v-if="protocolInfoPopup.NHISS_value != null">
                        <div class="container-info">
                          <p class="texto-regular" style="text-align: left; margin: 0;">NHISS: </p>
                          <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.NHISS_value }}
                          </p>
                        </div>
                      </div>
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">Contraindicações de trombólise:
                        </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.thrombolysis_contraindications) }}</p>
                      </div>
                    </div>
                    <div class="separate"></div>
                  </div>
                  <div class="container-info">
                    <p class="texto-regular" style="text-align: left; margin: 0;">Tipo: </p>
                    <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.case_type }}</p>
                  </div>
                  <div v-if="protocolInfoPopup.reopen_reason !== null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Motivo: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.reopen_reason }}</p>
                    </div>
                  </div>
                  <div class="container-info">
                    <p class="texto-regular" style="text-align: left; margin: 0;">Local: </p>
                    <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.case_location }}</p>
                  </div>
                  <div class="container-info">
                    <p class="texto-regular" style="text-align: left; margin: 0;">Prioridade: </p>
                    <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.case_priority }}</p>
                  </div>
                  <div class="container-info">
                    <p class="texto-regular" style="text-align: left; margin: 0;">Transferência: </p>
                    <p class="texto-regular-bold" style="text-align: left;">{{ formatBoolean(protocolInfoPopup.transfer)
                      }}</p>
                  </div>
                  <div v-if="protocolInfoPopup.note != null">
                    <div class="container-copy">
                      <div style="text-align: justify;">
                        <p class="texto-regular">História clinica: <strong class="texto-regular-bold">{{
    protocolInfoPopup.note }}</strong></p>
                      </div>
                      <div>
                        <button class="button-copy" @click="copyInfo(protocolInfoPopup.note)"
                          style="align-items: center; margin-bottom: 10px;">
                          <img src="../assets/copy-icon.png" alt="Descrição da imagem" style="margin-right: 5px;">
                          <span class="text-font-copy">COPIAR</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="separate"></div>
                  <div v-if="protocolInfoPopup.cardiovascular_disease_id != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Comorbidades: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.cardiovascular_disease_name }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.heartache != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Dor torácica: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.heartache) }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.hemodynamic_instability != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Instabilidade hemodinâmica: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.hemodynamic_instability) }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.probability_iam_id != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Probabilidade de IAM: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.probability_iam_name
                        }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.cardiological_risk_factor_id != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Fatores de Risco Cardiológicos: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.cardiological_risk_factor_name }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.patient_drugs != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Medicações em uso: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.patient_drugs }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.cardiolovascular_disease_id != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Comorbidades: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.cardiolovascular_disease_name }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.atheromatosis_id != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Ateromatose: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.atheromatosis_name }}
                      </p>
                    </div>
                  </div>

                  <div class="separate"></div>
                  <div v-if="protocolInfoPopup.pupillary_reflex != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Reflexo Pupilar: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.pupillary_reflex }}
                      </p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.sedation != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Sedação: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.sedation)
  }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.sedation != null && protocolInfoPopup.sedation == 0">
                    <div v-if="protocolInfoPopup.glasgow != null">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">Glasgow: </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    formatGlasgow(protocolInfoPopup.glasgow) }}</p>
                      </div>
                    </div>
                    <div v-if="protocolInfoPopup.motor_loss_location != null">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">Perda de força motora localização:
                        </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    formatMotorLossLocation(protocolInfoPopup.motor_loss_location) }}</p>
                      </div>
                      <div v-if="protocolInfoPopup.motor_loss_location != 'N'">
                        <div class="container-info">
                          <p class="texto-regular" style="text-align: left; margin: 0;">Perda de força motora
                            lateralidade: </p>
                          <p class="texto-regular-bold" style="text-align: left;">{{
    formatMotorLossSide(protocolInfoPopup.motor_loss_side) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.weight != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Peso: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.weight }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_agitation != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Descrição da agitação:</p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.patient_agitation }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.patient_suicidal_thoughts != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Apresenta ideias de suicídio? </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.patient_suicidal_thoughts) }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.patient_drug_intoxication != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Sinais de intoxicação por alguma droga: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.patient_drug_intoxication }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.patient_comorbidities != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Outras Comorbidades: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.patient_comorbidities }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.psychiatric_treatment != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Faz Tratamento Psiquiátrico: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.psychiatric_treatment) }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.sepsis != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Descrição dos sinais de sepse: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.sepsis }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.antimicrobial != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Antimicrobiano em uso: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.antimicrobial }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.invasive_devices != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Descrição dos Dispositivos Invasivos: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.invasive_devices }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.renal_dysfunction != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Causa da Disfunção Renal: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.renal_dysfunction }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.CKMB_quantitative != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">CKMB Quantitativo: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.CKMB_quantitative }}
                      </p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.tropo_quantitative != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Tropo Quantitativo: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.tropo_quantitative }}
                      </p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.tropo_unit != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Tropo Unidade: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.tropo_unit }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.CPK_quantitative != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">CPK Quantitativo: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.CPK_quantitative }}
                      </p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.blood_pressure != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Pressão Sanguínea: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.blood_pressure }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.apgar_qualitative != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">APGAR Qualitativo: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.apgar_qualitative }}
                      </p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.apgar_quantitative != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">APGAR Quantitativo: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.apgar_quantitative }}
                      </p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.diagnostic_hypothesis != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Hipótese diagnóstica: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.diagnostic_hypothesis
                        }}</p>
                    </div>
                    <div v-if="protocolInfoPopup.diagnostic_hypothesis_another != null">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">Outra Hipótese diagnóstica: </p>
                        <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.diagnostic_hypothesis_another }}</p>
                      </div>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.imaging_exam != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Exame de imagem: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.imaging_exam }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_fever != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Febre: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatBoolean(protocolInfoPopup.patient_fever) }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_balance != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Balanço Hídrico: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.patient_balance }}
                      </p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_glycemia != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Glicemia Capilar: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    formatGlycemia(protocolInfoPopup.patient_glycemia) }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_ventilation_type != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">VM (Ventilação Mecânica): </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.patient_ventilation_type }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_breath == 'AR'">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Respiração: </p>
                      <p class="texto-regular-bold" style="text-align: left;">Ar ambiente</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_breath == 'SUPORTE'">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Respiração: </p>
                      <p class="texto-regular-bold" style="text-align: left;">Suporte O2</p>
                    </div>
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Tipo de suporte O2: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
    protocolInfoPopup.patient_air_support }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_pam != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Pressão Arterial Média: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.patient_pam }}</p>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_fc != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Frequência cardíaca: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.patient_fc }}</p>
                    </div>
                  </div>
                  <div v-if="protocolInfoPopup.patient_vasoactive_type != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Droga Vasoativa: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{
                        formatBoolean(protocolInfoPopup.patient_vasoactive_type)
                        }}</p>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="protocolInfoPopup.protocol_info.specialty == 'Cardiologia'">
                      <div class="container-info">
                        <p class="texto-regular" style="text-align: left; margin: 0;">Droga Vasoativa: </p>
                        <p class="texto-regular-bold" style="text-align: left;">Não</p>
                      </div>
                    </div>
                  </div>

                  <div v-if="protocolInfoPopup.patient_nutrition != null">
                    <div class="container-info">
                      <p class="texto-regular" style="text-align: left; margin: 0;">Via de alimentação: </p>
                      <p class="texto-regular-bold" style="text-align: left;">{{ protocolInfoPopup.patient_nutrition }}
                      </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div v-if="enableButton">
            <div class="attentive-buttons">
              <button class="btn-back" :disabled="isButtonAtenderDisabled"
                @click="openAttendanceForm(protocolInfoPopup)">ATENDER</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    visibleModalInfoBot: Boolean,
    protocolInfoPopup: Object,
    enableButton: Boolean
  },
  computed: {
    ...mapGetters({
      UrlFile: "StateUrlFile"
    }),
  },
  data() {
    return {
      isButtonAtenderDisabled: false,
      isCollapedGeralInfo: false,
      isCollapedSolicitantVisible: false,
      isCollapedPacientVisible: false,
      isCollapedHistoricPacientVisible: false,
      isCollapedInfoVisible: false,
      isCollapedFilesVisible: false,
    };
  },
  methods: {
    ...mapActions(["SetProtocol", "GenerateFile"]),
    getTypeFile(extension) {
      if (extension.includes('pdf')) {
        return 'Documento';
      }
      else if (extension.includes('png') || extension.includes('jpg') || extension.includes('jpeg')) {
        return 'Imagem';
      }
      else if (extension.includes('mp3')) {
        return 'Áudio';
      }
      else if (extension.includes('mp4')) {
        return 'Vídeo';
      }
      else {
        return '';
      }
    },
    formatGlycemia(value) {
      if (value != null) {
        if (value == "ACIMA") {
          return "Acima de 180 mg/dl"
        }
        else if (value == "ABAIXO") {
          return "Abaixo de 60 mg/dl"
        }
        else {
          return "Não";
        }
      }
      else {
        return "Não";
      }
    },
    formatBoolean(value) {
      if (value != null) {
        if (value == 1) {
          return "Sim"
        }
        return "Não";
      }
      else {
        return "Não";
      }
    },
    formatGlasgow(value) {
      if (value != null) {
        if (value == 0) {
          return "Não Realizado"
        }
        return value;
      }
    },
    async handleItemHistoricClick(id) {
      localStorage.setItem('reportId', JSON.stringify(id));
      window.open('/historicalreview', '_blank');
    },
    async handleItemFileClick(id) {
      try {
        await this.GenerateFile(id)
        const fileUrl = this.UrlFile;
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      catch {
        this.$toast.error('Ocorreu um erro, tente novamente.', {
          timeout: 3000,
          closeOnClick: true
        });
      }
    },
    formatHistoric(item) {
      return item.created_at + ' - ' + item.specialist_name
    },
    formatMotorLossSide(value) {
      switch (value) {
        case 'B':
          return 'Bilateral';
        case 'L':
          return 'Esquerda';
        case 'R':
          return 'Direita';
        case 'N':
          return 'Não';
        default:
          return null;
      }
    },
    formatMotorLossLocation(value) {
      switch (value) {
        case 'B':
          return 'Ambos';
        case 'U':
          return 'Superiores';
        case 'D':
          return 'Inferiores';
        case 'N':
          return 'Não';
        default:
          return null;
      }
    },
    formatDocument(tipo, document) {
      if (document !== null) {
        if (tipo === "CPF") {
          document = document.replace(/\D/g, '');
          return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        }
        else {
          document = document.replace(/\D/g, '');
          return document.replace(/(\d{2})(\d{3})(\d{3})(\d{1})/, '$1.$2.$3-$4');
        }
      }
    },
    formatName(name) {
      if (name !== null) {
        let words = name.toLowerCase().split(" ");
        for (let i = 0; i < words.length; i++) {
          let word = words[i];
          words[i] = word.charAt(0).toUpperCase() + word.slice(1);
        }
        return words.join(" ");
      }
    },
    formatPhone(phone) {
      if (phone !== null) {
        const ddd = phone.substring(2, 4);
        const first = phone.substring(4, 9);
        const second = phone.substring(9);
        return `${ddd} ${first}-${second}`;
      }
    },
    calcularIdade(dataNascimento) {
      if (dataNascimento !== null) {
        var partes = dataNascimento.split(' ');
        var dataPartes = partes[0].split('/');
        var horaPartes = partes.length > 1 ? partes[1].split(':') : [0, 0];
        var dataNasc = new Date(dataPartes[2], dataPartes[1] - 1, dataPartes[0], horaPartes[0], horaPartes[1]);

        var hoje = new Date();
        var idade = hoje.getFullYear() - dataNasc.getFullYear();
        var mes = hoje.getMonth() - dataNasc.getMonth();
        var dia = hoje.getDate() - dataNasc.getDate();

        if (mes < 0 || (mes === 0 && dia < 0)) {
          idade--;
        }

        partes = dataNascimento.split(' ');
        var dataFormatada = partes[0]

        if (idade < 1) {
          var meses = (hoje.getMonth() - dataNasc.getMonth()) + (12 * (hoje.getFullYear() - dataNasc.getFullYear()));
          return dataFormatada + " (" + meses + " Meses)";
        } else {
          return dataFormatada + " (" + idade + " Anos)";
        }
      }
    },
    closeModalInfoBot() {
        this.isCollapedGeralInfo = false,
        this.isCollapedSolicitantVisible = false,
        this.isCollapedPacientVisible = false,
        this.isCollapedHistoricPacientVisible = false,
        this.isCollapedInfoVisible = false,
        this.isCollapedFilesVisible = false,
        this.$emit("close");
    },
    ChangeCollapedGeralInfo() {
      this.isCollapedGeralInfo = !this.isCollapedGeralInfo;
    },
    ChangeCollapedSolicitantVisible() {
      this.isCollapedSolicitantVisible = !this.isCollapedSolicitantVisible;
    },
    ChangeCollapedPacientVisible() {
      this.isCollapedPacientVisible = !this.isCollapedPacientVisible;
    },
    ChangeCollapedHistoricPacientVisible() {
      this.isCollapedHistoricPacientVisible = !this.isCollapedHistoricPacientVisible;
    },
    ChangeCollapedInfoVisible() {
      this.isCollapedInfoVisible = !this.isCollapedInfoVisible;
    },
    ChangeCollapedFilesVisible() {
      this.isCollapedFilesVisible = !this.isCollapedFilesVisible;
    },
    async openAttendanceForm(protocolInfoPopup) {
      try {
        this.isButtonAtenderDisabled = true;
        await this.SetProtocol(protocolInfoPopup);
        this.$router.push({ name: 'Report' });
      }
      catch (erro) {
        if (erro.message === '422') {
          this.$toast.warning('Relatório ja criado.', {
            timeout: 3000,
            closeOnClick: true
          });
        }
        else {
          this.$toast.error('Ocorreu um erro, tente novamente.', {
            timeout: 3000,
            closeOnClick: true
          });
        }
      }
    },
    copyInfo(info) {
      navigator.clipboard.writeText(info);
      this.$toast.success('Texto copiado.', {
        timeout: 3000,
        closeOnClick: true
      });
    }
  },
};
</script>

<style>
.modal-dialog-n {
  background-color: #fff;
  width: 600px;
  height: 450px;
  max-width: 100%;
  border-radius: 20px;
}


.button-copy {
  padding: 0;
  border: none;
  background: none;
}

.text-font-copy {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.btn-info-bot {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #000;
  background-color: #DBDBDB;
  padding: 0;
  border: none;
}

.btn-solicitante {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  color: #000;
  background-color: #DBDBDB;
  padding: 0;
  border: none;
}

.container-tittle {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imagem-tittle {
  width: 35px;
  height: 35px;
}

.container-info {
  display: flex;
}

.clicavel {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.texto-tittle {
  text-align: center;
  margin-top: 15px;
  margin-left: 10px;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
}

.texto-institution {
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  margin-top: 10px;
  color: #777777;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.texto-hospital {
  text-align: center;
  margin-left: 10px;
  margin-top: 10px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #777777;
}

.btn-back {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200EE;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 100px;
  cursor: pointer;
  margin: 0 auto;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  color: #666666;
  cursor: pointer;
}

.container-copy {
  background-color: #EFEFEF;
  margin-top: 15px;
}

.link_excel{
    color:#6200ee;
    padding: 20px 0 0 0;
}

.link_excel a{
    color:#6200ee;
    text-decoration: underline;
    font-weight: bold;
}

.img_link_external_file{
    margin: 0 7px 0 0;
}

div.obs-list{
    padding: 20px 0 0 0;
    text-align: left;
    margin: 0 0 0 -31px;
}

div ul li {
    list-style: none;
}
</style>
