<template>
  <div>
    <div v-for="field in reportFields " :key="field.id">
      <div v-if="field.visible">
        <div v-if="field.name == 'specialized_prescription'">
          <div v-if="loadingMemed">
            <p class="texto-regular-bold" style="margin-top: 15px; color: #777777;">Prescrição médica VIA MEMED
              (opcional):</p>
            <button type="button" @click="loadMemed()" style="margin-top: 5px; background-color: #5D63FE; width: 300px;"
                    class="btn btn-primary">
              <img src="../assets/logo-memed.png" alt="Ícone de nova prescrição" width="30" height="20"
                   style="vertical-align: middle; margin-right: 30px;">
              + NOVA PRESCRIÇÃO
            </button>
          </div>
          <div v-else>
            <p class="texto-regular-bold" style="margin-top: 15px; color: #777777;">Carregando prescrição Memed...</p>
          </div>
        </div>

        <template v-if="field.field_type === 'textarea'">
          <div class="form-group mt-4">
            <label class="texto-regular-bold" style="color: #777777" :for="field.name">{{ field.description }}</label>
            <textarea class="form-control text-form-pep" v-model="field.value" :id="field.name"
                      :maxlength="field.length" :rows="field.rows"
                      @input="validateInput(field.name, $event)"></textarea>
          </div>
        </template>
        <template v-else-if="field.field_type === 'select'">
          <div class="form-group col-6 mt-4">
            <div class="texto-regular-bold" style="color: #777777" :for="field.name">
              {{ field.description }}
            </div>
            <select class="form-control" @change="onChangeSelect($event.target.value, field)" v-model="field.value">
              <option disabled value="">Selecione uma opção</option>
              <option v-for="option in field.options" :value="option.value" :key="option.description">
                {{ option.description }}
              </option>
            </select>
          </div>
        </template>

        <template v-else-if="field.field_type === 'select-search'">
          <div class="form-group col-6 mt-4">
            <div class="texto-regular-bold" style="color: #777777" :for="field.name">
              {{ field.description }}
            </div>

            <Select2 class="form-control" v-model="field.value" :settings=
                "{
                allowClear: true,
                placeholder: {
                  id: reportInfo.cid_subcategory? reportInfo.cid_subcategory.id : null,
                  text: reportInfo.cid_subcategory ? reportInfo.cid_subcategory.description:null
                },
                language: {
                  errorLoading: function () {
                    return 'Os resultados não puderam ser carregados.';
                  },
                  inputTooLong: function (args) {
                    var overChars = args.input.length - args.maximum;

                    var message = 'Por favor apague ' + overChars + ' ';

                    if (overChars != 1) {
                      message += 'caracteres';
                    }
                    else {
                      message += 'carácter';
                    }

                    return message;
                  },
                  inputTooShort: function (args) {
                    var remainingChars = args.minimum - args.input.length;

                    var message = 'Por favor, digite ' + remainingChars + ' ou mais caracteres';

                    return message;
                  },
                  loadingMore: function () {
                    return 'Carregando mais resultados...';
                  },
                  maximumSelected: function (args) {
                    var message = 'Você pode selecionar apenas ' + args.maximum + ' ite';

                    if (args.maximum != 1) {
                      message += 'ns';
                    }
                    else {
                      message += 'm';
                    }

                    return message;
                  },
                  noResults: function () {
                    return 'Nenhum resultado encontrado';
                  },
                  searching: function () {
                    return 'Buscando…';
                  },
                  removeAllItems: function () {
                    return 'Remover todos os itens';
                  }
                },
                width: '100%',
                'ajax': {
                  url: api_url+'/resource/cid_subcategory',
                  processResults: function (data) {
                    return {
                      results: data.data
                    };
                  },
                  data: function (params) {
                    return {
                      search: params.term
                    }
                  },
                  dataType: 'json',
                  delay: 250,
                },
                minimumInputLength: 3
              }"/>
          </div>
        </template>

        <template v-else-if="field.field_type === 'radio'">
          <div class="form-group col-6 mt-4">
            <label class="texto-regular-bold" style="color: #777777" :for="field.name">{{ field.description }}</label>
            <br/>
            <label v-for="( optionRadio, index ) in  field.options " :key="index">
              <input type="radio" :value="optionRadio.value" v-model="field.value"
                     @change="onChangeRadio($event.target.value, field)" :id="field.name + index"/>
              {{ optionRadio.description }}
              &nbsp;&nbsp;&nbsp;&nbsp;
            </label>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  props: {
    reportFields: Array,
    reportInfo: Object
  },
  computed: {
    ...mapGetters({
      TokenMemed: "StateTokenMemed"
    }),
  },
  async mounted() {
    this.initializeValueSubCategorieCid();
    await this.GetTokenMemed();
    if (this.TokenMemed != null) {
      let scriptUrl = process.env.VUE_APP_API_MEMED;
      let script = document.querySelector(`script[src="${scriptUrl}"]`);
      if (!script) {
        script = document.createElement('script');
        script.src = scriptUrl;
        script.dataset.token = this.TokenMemed;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);
        script.onload = () => {
          window.MdSinapsePrescricao.event.add('core:moduleInit', async (module) => {
            if (module.name === 'plataforma.prescricao') {
              await window.MdHub.command.send("plataforma.prescricao", "setFeatureToggle", {
                historyPrescription: true,
                optionsPrescription: false,
                emovePrescription: true,
                copyMedicalRecords: true,
                buttonClose: true,
                guidesOnboarding: false,
                dropdownSync: false,
                showProtocol: true,
                showHelpMenu: false,
              });
              await window.MdHub.command.send('plataforma.prescricao', 'setPaciente', {
                idExterno: this.reportInfo.protocol_update_info.protocol_info.patient_info.id,
                nome: this.reportInfo.protocol_update_info.protocol_info.patient_info.name,
                telefone: this.getPhone(this.reportInfo),
                cpf: this.reportInfo.protocol_update_info.protocol_info.patient_info.document,
                data_nascimento: this.reportInfo.protocol_update_info.protocol_info.patient_info.birthdate.substring(0, 10),
                endereco: "Alameda Vicente Pinzon, 54, 11º andar - Vila Olímpia",
                cidade: "São Paulo"
              });
              await window.MdHub.event.add('prescricaoImpressa', (prescriptionData) => {
                this.atribuirValor("specialized_prescription", this.formatarMedicamentos(prescriptionData.prescricao.medicamentos));
              });
            }
            this.isProcessingLoadingMemedComplete += 1;
            if (this.isProcessingLoadingMemedComplete == 10) {
              this.executeLoadingTrue();
            }
          });
        };
      } else {
        this.loadingMemed = true;
      }
    }
  },
  data() {
    return {
      loadingMemed: false,
      api_url: process.env.VUE_APP_API_URL,
      isProcessingLoadingMemedComplete: 0
    };
  },
  methods: {
      initializeValueSubCategorieCid() {
          if (this.reportInfo && this.reportInfo.cid_subcategory) {
              this.reportFields.forEach(field => {
                  if (field.field_type === "select-search" && !field.value) {
                      field.value = this.reportInfo.cid_subcategory.id;
                  }
              });
          }
      },
    ...mapActions(["GetTokenMemed"]),
    ...mapActions(["GetDataConditional"]),
    formatarMedicamentos(array) {
      return array.map(medicamento => {
        const {nome, descricao, posologia, quantidade} = medicamento;
        let resultString = '';
        if (nome) {
          resultString += `Descrição: ${nome},\n`;
        }
        if (descricao) {
          resultString += `${descricao} \n`;
        }
        if (posologia) {
          const posologiaSemTags = posologia.replace(/<\/?p>/g, '');
          resultString += `Posologia: ${posologiaSemTags} \n`;
        }
        if (quantidade) {
          resultString += `Quantidade: ${quantidade}\n`;
        }
        return resultString + '\n\n';
      }).join('');
    },
    atribuirValor(nomeCampo, novoValor) {
      const reportFieldsCopy = this.reportFields;
      for (let i = 0; i < reportFieldsCopy.length; i++) {
        if (reportFieldsCopy[i].name === nomeCampo) {
          reportFieldsCopy[i].value = novoValor;
        }
      }
      this.$emit('reportFields', reportFieldsCopy);
    },
    executeLoadingTrue() {
      this.loadingMemed = true;
    },
    getPhone(reportInfo) {
      const responsiblePhones = reportInfo?.protocol_update_info?.responsible_info?.phones;
      const nursePhones = reportInfo?.protocol_update_info?.nurse?.phones;
      if (responsiblePhones?.length > 0) {
        return responsiblePhones[0];
      } else if (nursePhones?.length > 0) {
        return nursePhones[0];
      } else {
        return "";
      }
    },
    async loadMemed() {
      if (this.loadingMemed) {
        await window.MdHub.command.send('plataforma.prescricao', 'setPaciente', {
          idExterno: this.reportInfo.protocol_update_info.protocol_info.patient_info.id,
          nome: this.reportInfo.protocol_update_info.protocol_info.patient_info.name,
          telefone: this.getPhone(this.reportInfo),
          cpf: this.reportInfo.protocol_update_info.protocol_info.patient_info.document,
          data_nascimento: this.reportInfo.protocol_update_info.protocol_info.patient_info.birthdate.substring(0, 10),
          endereco: "Alameda Vicente Pinzon, 54, 11º andar - Vila Olímpia, 04547-130",
          cidade: "São Paulo"
        });
        await window.MdHub.event.add('prescricaoImpressa', (prescriptionData) => {
          this.atribuirValor("specialized_prescription", this.formatarMedicamentos(prescriptionData.prescricao.medicamentos));
        });
        window.MdHub.module.show('plataforma.prescricao');
      }
    },
    validateInput(fieldName, event) {
      const regex =
          /[^a-zA-Z0-9,.!?áàâãéèêíïóôõöúçÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇ\s()\-:+%#&_/\\|{}[\]#@;'$="]/gu;
      const correctedValue = event.target.value.replace(regex, "");
      event.target.value = correctedValue;
      this[fieldName] = correctedValue;
    },
    onChangeRadio(event, field) {
      field.rules.forEach((rule) => {
        const targetField = this.reportFields.find(
            (item) => item.name === rule.parameters[2]
        );
        if (targetField && rule.parameters[0] === "field_equals") {
          if (rule.name === "reveals_to") {
            targetField.visible = rule.parameters[1] === event;
            targetField.value = "";
          } else if (rule.name === "forces_to") {
            targetField.required = rule.parameters[1] === event;
            targetField.value = "";
          }
        }
      });
    },
    async onChangeSelect(event, field) {
      field.rules.forEach(async (rule) => {
        const targetField = this.reportFields.find(
            (item) => item.name === rule.parameters[2]
        );
        if (targetField && rule.parameters[0] === "field_equals") {
          if (rule.name === "reveals_to") {
            targetField.visible = rule.parameters[1] === event;
            targetField.value = null;
          } else if (rule.name === "forces_to") {
            targetField.required = rule.parameters[1] === event;
            targetField.value = null;
          }
        }
      });
    },
  },
};
</script>

<style>
.custom-button-save {
  background: none;
  border: none;
  color: #6200ee;
  cursor: pointer;
  font-size: 16px;
  margin-left: 15px;
}

.aling-count {
  float: right;
  margin-top: 5px;
  margin-right: 5px;
}

.text-form-pep {
  border-radius: 10px;
}

.button-back {
  background-color: #ffff;
  border: none;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: #6200ee;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 9999;
}

.button-image {
  width: 20px;
  height: 20px;
}

.attentive-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: center;
}

.texto-regular-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 5px;
}

.separate {
  display: inline-block;
  align-self: stretch;
  width: 2px;
  min-height: 1em;
  opacity: 0.25;
}

.btn-transfer {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: none;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-back-queue {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #6200ee;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.btn-review {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6200ee;
  border: 1px solid #6200ee;
  border-radius: 20px;
  padding: 10px;
  width: 300px;
  cursor: pointer;
  margin: 0 auto;
}

.texto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
}

.container-info {
  display: flex;
}

.loading {
  width: 50px;
  height: 50px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
